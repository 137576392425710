<template>
  <div id="basicInfo">
    <ul class="b-router-ul">
      <router-link v-for="(val,i) in ulText" :to="val.url" :key='i'>
        <li class="b-router-li" :class="{'b-router-li-acitve':liAcitve == i}" @click='liAcitve = i'>
            {{val.txt}}
        </li>
      </router-link>
    </ul>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      liAcitve: 0,
      isRouterAlive: true,
      ulText: [
        {txt: '机构介绍', index: 0, url:'/AddSpaceOrganization'},
        {txt: '服务团队', index: 1, url:'/serverTeam'},
        {txt: '合作资源', index: 2, url:'/cooperativeResources'},
        {txt: '活动案例', index: 3, url:'/activityCases'},
        {txt: '媒体发布', index: 4, url:'/media'},
      ]
    }
  },
  provide () {
    return {
      reload: this.reload
    }
  },  
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }    
  },
  created() {
    for(let i in this.ulText){
      this.ulText[i].url == this.$route.path && (this.liAcitve = this.ulText[i].index)
    }
  }
}
</script>
<style lang="less" scoped>
  .b-router-ul{
    .b-router-li{
      display: inline-block;
      width: 154px;
      height: 37px;
      line-height: 37px;
      background:rgba(239,240,240,1);
      font-size: 18px;
      text-align: center;
      border-right: 1px #ffffff solid;
      cursor: pointer;
    }
    .b-router-li-acitve{
      color: rgba(51, 123, 251, 1);
      border-top: rgba(51, 123, 251, 1) 4px solid;
      background-color: #ffffff;
    }
  }
</style>